import React from 'react';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { HostAppProxyProvider } from './contexts/HostAppProxyContext';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c9da01dabfb74414b74ed51935e6a3e9@sentry.io/1487794',
  });
}
const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HostAppProxyProvider>
        <App />
      </HostAppProxyProvider>
    </ConnectedRouter>
  </Provider>,
  target
);

serviceWorker.unregister();
