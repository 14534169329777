import React from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Menu, Label } from 'semantic-ui-react';
import { useHostAppProxy } from 'src/contexts/HostAppProxyContext';

const UpgradeMenuItem: React.FC = () => {
  const { t } = useTranslation();
  const {
    settings: { showPaymentInfo },
  } = useHostAppProxy();

  const location = useLocation();
  const history = useHistory();

  const userProfile = useSelector((state: any) => state.user.userProfile);
  const showUpgrade = (role: string, account: any, pathname: string) => {
    return (
      ['WRITER', 'BOTH'].includes(role) &&
      account &&
      account.product &&
      account.product.internalId === 'free' &&
      pathname !== '/read' &&
      !pathname.includes('/books')
    );
  };

  if (
    !showPaymentInfo ||
    !userProfile ||
    !showUpgrade(userProfile.role, userProfile.account, location.pathname)
  ) {
    return null;
  }
  return (
    <Menu.Item key="upgrade" fitted link>
      <Label
        size="mini"
        color="orange"
        onClick={() => history.push('/products')}
      >
        {t('UpgradeSubscriptionTitle').toUpperCase()}
      </Label>
    </Menu.Item>
  );
};

export default UpgradeMenuItem;
