import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Media } from 'src/utils/Media';

import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { Icon, Button, Responsive } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { authUtil } from '../../../utils';
import { getFollowStatus, toggleFollowStatus } from '../../../modules/book';

const BookFollowButton = ({ t }) => {
  const currentBook = useSelector(state => state.book.currentBook);
  const followStatus = useSelector(state => state.book.followStatus);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setup();
  }, [currentBook]);

  const setup = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (!currentBook) {
      return;
    }
    dispatch(getFollowStatus({ idToken, bookId: currentBook._id }));
  };

  const toggleFollowBook = async action => {
    const idToken = await authUtil.getFreshIdToken();
    if (!idToken) {
      // get the user signed in
      goToSignIn();
      return;
    }
    if (action) {
      dispatch(
        toggleFollowStatus({ idToken, bookId: currentBook._id, action })
      );
      return;
    }
    // no action provided, just toggle status
    if (
      followStatus[currentBook._id] &&
      followStatus[currentBook._id].following
    ) {
      dispatch(
        toggleFollowStatus({
          idToken,
          bookId: currentBook._id,
          action: 'unfollow',
        })
      );
    } else {
      dispatch(
        toggleFollowStatus({
          idToken,
          bookId: currentBook._id,
          action: 'follow',
        })
      );
    }
  };

  const goToSignIn = () => {
    history.push(
      `/signin?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`
    );
  };

  // rendering
  if (!currentBook) {
    return null;
  }

  return (
    <Button
      key="bookFollowButton"
      compact
      size="mini"
      onClick={() => toggleFollowBook()}
      content={
        <span>
          <Icon key="bookFollowButtonIcon" name="feed" />
          <Media
            key="bookFollowButtonResponsiveSmallKey"
            as="span"
            greaterThanOrEqual="tablet"
          >
            {followStatus[currentBook._id] &&
            followStatus[currentBook._id].following
              ? t('Following')
              : t('Follow')}
          </Media>
          <span
            key="bookFollowButtonSpanKey"
            style={{ margin: '5px 5px 5px 5px' }}
          >
            ·
          </span>
          <span key="bookFollowButtonSpanIdKey">
            {(followStatus[currentBook._id] &&
              followStatus[currentBook._id].followers) ||
              0}
          </span>
        </span>
      }
      style={{ marginLeft: 'auto', height: 24 }}
    />
  );
};

export default withTranslation()(withRouter(BookFollowButton));
