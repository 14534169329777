import i18n from '../utils/i18n/i18n';
import { toast } from 'react-toastify';

export const SET_ACCOUNT_USERS = 'app/SET_ACCOUNT_USERS';
export const REMOVE_ACCOUNT_USER = 'app/REMOVE_ACCOUNT_USER';
export const SET_ACTIVE_ACCOUNT = 'app/SET_ACTIVE_ACCOUNT';
const initialState = {
  activeAccount: undefined,
  accountUsers: [],
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ACCOUNT_USER:
      return {
        ...state,
        accountUsers: [
          ...state.accountUsers.filter(
            accountUsers => accountUsers.user._id !== action.userId
          ),
        ],
      };
    case SET_ACCOUNT_USERS:
      return {
        ...state,
        accountUsers: action.users,
      };
    case SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.account,
      };
    default:
      return state;
  }
};

// actions
export const fetchAccountUsers = ({ idToken, accountId }) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${accountId}/members`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(users =>
        dispatch({
          type: SET_ACCOUNT_USERS,
          users,
        })
      )
      .catch(() => {
        toast.error(
          i18n.t('FailedToFetchX', { x: i18n.t('Users').toLowerCase() })
        );
      });
  };
};

export const removeAccountUser = ({ idToken, accountId, userId }) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/accounts/${accountId}/members/${userId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
      }
    )
      .then(res => res.json())
      .then(() =>
        dispatch({
          type: REMOVE_ACCOUNT_USER,
          accountId,
          userId,
        })
      )
      .catch(() => {
        toast.error(
          i18n.t('FailedToFetchX', { x: i18n.t('Users').toLowerCase() })
        );
      });
  };
};

export const setActiveAccount = ({ account }) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ACCOUNT,
      account,
    });
  };
};

export const resetActiveAccount = () => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ACCOUNT,
      account: undefined,
    });
  };
};
