import React from 'react';

import '../../App.css';

// i18n
import { useTranslation } from 'react-i18next';

// Routes
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';

// Components
import styled from 'styled-components';
import { Icon, Dropdown, Loader } from 'semantic-ui-react';

import firebase from '../../config/fire';

import { colorModes, loggedOut, updateUserProfile } from '../../modules/user';

import { resetActiveAccount } from '../../modules/account';

import Avatar from '../../components/avatar/avatar';

import { authUtil, productCapUtil } from '../../utils';
import SupportModal from '../../components/menu/supportModal';
import { useHostAppProxy } from 'src/contexts/HostAppProxyContext';

const UserDropdownWrapper = styled(Dropdown)`
  > i.dropdown.icon {
    margin: 0px 0px 0px 0px !important;
  }
`;

const UserDropdown: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    signOut: signOutByProxy,
    settings: { showPaymentInfo, isWrapped },
  } = useHostAppProxy();

  const [showSupportModal, setShowSupportModal] = React.useState(false);

  const userProfile = useSelector((state: any) => state.user.userProfile);
  const activeAccount = useSelector(
    (state: any) => state.account.activeAccount
  );

  const handleSignout = async () => {
    if (userProfile.accountMembership.length > 1) {
      // if this user has several accounts, just disable the active one
      // so they can choose to switch account instead of signing out completely
      dispatch(resetActiveAccount());
    } else {
      dispatch(resetActiveAccount());
      dispatch(loggedOut());
      await signOutByProxy();
      firebase.auth().signOut();
    }
  };

  const handleSignIn = () => {
    history.push('/signin');
  };

  const openSupportConversation = () => {
    setShowSupportModal(false);
    history.push('/inbox/conversations/support');
  };

  const toggleSupportModal = () => {
    setShowSupportModal(!showSupportModal);
  };

  const toggleColorMode = async () => {
    if (!userProfile) {
      return;
    }
    const currentIndex = colorModes.findIndex(
      mode => mode === userProfile.readerSettings.colorMode
    );
    const nextIndex = (currentIndex + 1) % colorModes.length;
    const nextColorMode = colorModes[nextIndex];
    const idToken = await authUtil.getFreshIdToken();
    dispatch(
      updateUserProfile(idToken, {
        readerSettings: {
          ...userProfile.readerSettings,
          colorMode: nextColorMode,
        },
      })
    );
  };

  const canEditAccount = productCapUtil.canEditAccount({
    activeAccount,
    userProfile,
  });

  return (
    <>
      <SupportModal
        key="SupportModal"
        onClose={toggleSupportModal}
        open={showSupportModal}
        onStartSupportChat={openSupportConversation}
        signedIn={!!userProfile}
      />
      {isWrapped && !userProfile ? (
        <Loader inline active size="mini" />
      ) : (
        <UserDropdownWrapper
          key="dropdown"
          item
          style={{ height: 40 }}
          trigger={
            userProfile ? (
              <Avatar
                name={userProfile && userProfile.displayName}
                src={!userProfile ? '/assets/images/BR-square.png' : null}
              />
            ) : (
              t('SignIn')
            )
          }
        >
          <Dropdown.Menu>
            {userProfile && [
              <Dropdown.Header key="user-header" content={t('UserInfo')} />,
              <Dropdown.Item
                key="profile"
                content={t('Profile')}
                icon="user"
                onClick={() => history.push('/account/profile')}
              />,
              <Dropdown.Item
                key="settings"
                content={t('Settings')}
                icon="settings"
                onClick={() => history.push('/account/settings')}
              />,
              <Dropdown.Item
                key="color-mode"
                icon="lightbulb"
                content={t('ColorMode')}
                onClick={toggleColorMode}
              />,
              canEditAccount && (
                <Dropdown.Header key="account-header" content={t('Account')} />
              ),
              canEditAccount && (
                <Dropdown.Item
                  key="members"
                  content={t('Members')}
                  icon="users"
                  onClick={() => history.push('/account/members')}
                />
              ),
              canEditAccount && showPaymentInfo && (
                <Dropdown.Item
                  key="subscription"
                  content={t('Subscription')}
                  icon="payment"
                  onClick={() => history.push('/account/subscription')}
                />
              ),
              <Dropdown.Divider key="account-divider" />,
              // Only show reader bonus option if relevant
              userProfile.maxReadersPerBook > -1 && (
                <Dropdown.Item
                  key="reader-bonus"
                  content={t('GetMoreReaders')}
                  icon="certificate"
                  onClick={() => history.push('/account/reader-bonus')}
                />
              ),
            ]}
            <Dropdown.Item
              as="a"
              key="supportContextMenu"
              onClick={toggleSupportModal}
            >
              <Icon name="help circle" />
              <span>{t('Support')}</span>
            </Dropdown.Item>
            <Dropdown.Item
              key="sign-in-or-out"
              onClick={userProfile ? handleSignout : handleSignIn}
              style={{ alignSelf: 'flex-end' }}
              content={[
                <Icon key="icon" name={userProfile ? 'log out' : 'sign-in'} />,
                <span key="text" style={{ marginLeft: 5 }}>
                  {userProfile
                    ? userProfile.accountMembership.length > 1
                      ? t('SwitchAccount')
                      : t('SignOut')
                    : t('SignIn')}
                </span>,
              ]}
            />
          </Dropdown.Menu>
        </UserDropdownWrapper>
      )}
    </>
  );
};

export default UserDropdown;
