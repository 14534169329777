import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';

import BetaOpportunityItem from '../discover/betaOpportunityItem';

import { updateReadingList } from '../../modules/book';
import { useDispatch, useSelector } from 'react-redux';
import { authUtil } from 'src/utils';

const ReadingList: React.FC = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector((state: any) => state.user.userProfile);
  const reading = useSelector((state: any) => state.book.reading);

  const fetchReadingList = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (idToken) {
      dispatch(updateReadingList(idToken));
    }
  };

  useEffect(() => {
    fetchReadingList();
  }, []);

  if (userProfile === undefined) {
    return <Loader />;
  }
  return (
    <div
      id="reading-list"
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 10px 0px 10px',
      }}
    >
      {reading &&
        reading.length > 0 &&
        reading.map(book => (
          <BetaOpportunityItem
            key={book._id}
            book={book}
            descriptionMaxLength={500}
            showLeaveOption
          />
        ))}
    </div>
  );
};

export default ReadingList;
