import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

export enum ProxyEventTypes {
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN',
  WEB_APP_READY = 'WEB_APP_READY',
  RECEIVED_MESSAGE = 'RECEIVED_MESSAGE',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  MESSAGE = 'MESSAGE',
}

const CUSTOM_AUTH_TOKEN_KEY = 'customAuthToken';
const SHOW_PAYMENT_INFO_KEY = 'showPaymentInfo';
const IS_WRAPPED_IN_HOST_APP_KEY = 'isWrapped';

interface ProxyHostMessage {
  type: string;
  data?: any;
}

interface Settings {
  customAuthToken?: string;
  showPaymentInfo?: boolean;
  isWrapped?: boolean;
}

interface HostAppProxyContextType {
  settings: Settings;
  sendMessageToHost: (message: ProxyHostMessage) => void;
  signOut: () => Promise<void>;
}

// Create a context with an empty default value
const HostAppProxyContext = createContext<HostAppProxyContextType | undefined>(
  undefined
);

interface ProxyProviderProps {
  children: ReactNode;
}

export const HostAppProxyProvider = ({ children }: ProxyProviderProps) => {
  const [settings, setSettings] = useState<Settings>({});

  // Handler to receive messages from the host
  const handleMessage = (event: MessageEvent) => {
    try {
      // Attempt to parse the message data
      let messageData = event.data;
      if (typeof messageData === 'string') {
        try {
          messageData = JSON.parse(messageData);
        } catch (e) {
          // Not JSON, ignore this message
          return;
        }
      }

      // Check if the message is intended for HostAppProxyProvider
      if (
        messageData &&
        messageData.type &&
        Object.values(ProxyEventTypes).includes(messageData.type)
      ) {
        // sendMessageToHost({ type: 'RECEIVED_MESSAGE', data: messageData });

        switch (messageData.type) {
          case ProxyEventTypes.UPDATE_AUTH_TOKEN:
            sendMessageToHost({
              type: ProxyEventTypes.MESSAGE,
              data: `Received new auth token: ${messageData.data?.customAuthToken}`,
            });
            localStorage.setItem(
              CUSTOM_AUTH_TOKEN_KEY,
              messageData.data?.customAuthToken
            );
            setSettings({
              ...settings,
              customAuthToken: messageData.data?.customAuthToken,
              isWrapped: true,
            });
            break;
          default:
            console.log('Unknown message type:', messageData.type);
            break;
        }
      }
    } catch (error) {
      // Silently ignore any errors
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [settings]);

  useEffect(() => {
    sendMessageToHost({
      type: ProxyEventTypes.MESSAGE,
      data: `custom aut token changed: ${settings.customAuthToken}`,
    });
  }, [settings.customAuthToken]);

  // Load initial settings from localStorage
  useEffect(() => {
    sendMessageToHost({
      type: ProxyEventTypes.WEB_APP_READY,
    });
    const showPaymentInfoStr = localStorage.getItem(SHOW_PAYMENT_INFO_KEY);
    const isWrappedInHostApp = localStorage.getItem(IS_WRAPPED_IN_HOST_APP_KEY);
    let showPaymentInfo = true;
    let isWrapped = false;
    if (showPaymentInfoStr) {
      console.log('showPaymentInfoStr:', showPaymentInfoStr);

      showPaymentInfo = showPaymentInfoStr === 'true';
    }
    if (isWrappedInHostApp) {
      isWrapped = isWrappedInHostApp === 'true';
    }
    console.log({ settings });

    setSettings({
      ...settings,
      showPaymentInfo,
      isWrapped,
    });
  }, []);

  // Function to send messages to the host
  const sendMessageToHost = (message: ProxyHostMessage) => {
    console.log('sendMessageToHost:', message);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      // console.log('ReactNativeWebView is not available on the window object');
    }
  };

  const signOut = async () => {
    if (window.ReactNativeWebView) {
      // clear local storage
      localStorage.removeItem(CUSTOM_AUTH_TOKEN_KEY);
      sendMessageToHost({
        type: ProxyEventTypes.SIGN_OUT,
      });
    } else {
      // console.log('ReactNativeWebView is not available on the window object');
    }
  };

  return (
    <HostAppProxyContext.Provider
      value={{ settings, sendMessageToHost, signOut }}
    >
      {children}
    </HostAppProxyContext.Provider>
  );
};

// Custom hook to use the HostAppProxyContext
export const useHostAppProxy = (): HostAppProxyContextType => {
  const context = useContext(HostAppProxyContext);
  if (!context) {
    throw new Error(
      'useHostAppProxy must be used within a HostAppProxyProvider'
    );
  }
  return context;
};
