import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import styled from 'styled-components';
import { Button, Container, Message, Image } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCurrentBook, resetCurrentBook } from '../../modules/book';
import { resetActiveAccount } from '../../modules/account';
import Readers from '../readers';
import Comments from '../feedback/comments';
import Reports from '../feedback/reports';
import Survey from '../content/survey';
import Content from '../content';
import BookPost from '../bookUpdates';
import BookCover from './bookCover';
import Collaborators from './collaborators';
import BookMessages from './bookMessages';
import BookOverview from './bookOverview';
import BookFollowers from './followers';
import RestrictedBookRoute from './restrictedBookRoute';
import Reviews from '../feedback/reviews';
import BasicSignIn from '../signin/basicSignIn';

import { authUtil, accessUtil } from '../../utils';
import { getCoverImage } from './bookCover/coverWrapper';
import { Helmet } from 'react-helmet';
import BookSummary from './bookSummary';

const BookContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BookPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch('/books/:bookId');
  console.log('match', match);
  const location = useLocation();
  const { bookId } = useParams<{ bookId: string }>();

  const activeAccount = useSelector(
    (state: any) => state.account.activeAccount
  );
  const currentBook = useSelector((state: any) => state.book.currentBook);
  const userProfile = useSelector((state: any) => state.user.userProfile);

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setup(true);
    return () => {
      dispatch(resetCurrentBook());
    };
  }, [bookId]);

  const setup = async (forceSetup?: boolean) => {
    const idToken = await authUtil.getFreshIdToken();
    const { token } = queryString.parse(location.search);
    if (bookId !== 'first' && (forceSetup || currentBook?._id !== bookId)) {
      dispatch(resetCurrentBook());
      dispatch(
        fetchCurrentBook({ idToken, bookId: bookId, token }, (result: any) => {
          if (result.errorMessage) {
            setErrorMessage(t(result.errorMessage));
          }
        })
      );
    }
  };

  const switchAccount = () => {
    dispatch(resetActiveAccount());
  };

  if (!currentBook) {
    if (errorMessage) {
      return (
        <Container
          text
          style={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Image src="/assets/images/BR-orange-horizontal.png" />
          <Message>{errorMessage}</Message>
          {!userProfile && <BasicSignIn />}
        </Container>
      );
    }
  }
  if (
    currentBook &&
    !['reader', 'collaborator'].includes(currentBook.role) &&
    currentBook.account !== (activeAccount && activeAccount._id)
  ) {
    // user has access to book, but is currently on the wrong account
    return (
      <Container
        text
        style={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Image src="/assets/images/BR-orange-horizontal.png" />
        <Message>{t('ContentBelongsToAnotherAccount')}</Message>
        <Button content={t('SwitchAccount')} onClick={switchAccount} />
      </Container>
    );
  }

  const editable = accessUtil.isAllowed(currentBook?.access, ['edit']);

  return (
    <BookContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentBook?.title}</title>
        <meta
          property="og:title"
          content={`${t('Read')} ${currentBook?.title} ${t('by')} ${
            currentBook?.authorName
          } ${t('onBetaReader')}`}
        />
        <meta property="og:site_name" content="BetaReader.io" />
        <meta property="og:description" content={currentBook?.description} />
        <meta property="og:image" content={getCoverImage(currentBook)} />
      </Helmet>
      {/* <Menu */}
      <Switch key="views">
        <Route
          key="overview-redirect"
          exact
          path={`${match?.path}`}
          render={() => (
            <Redirect to={`/books/${bookId}/overview${location.search}`} />
          )}
        />
        <Route
          key="settings"
          path={`${match?.path}/overview`}
          component={BookOverview}
        />
        <Route
          key="settings"
          path={`${match?.path}/summary`}
          component={BookSummary}
        />
        <RestrictedBookRoute
          key="content"
          path={`${match?.path}/content`}
          component={Content}
        />
        <RestrictedBookRoute
          key="readers"
          path={`${match?.path}/readers`}
          component={Readers}
        />
        <RestrictedBookRoute
          key="collaborators"
          path={`${match?.path}/collaborators`}
          component={Collaborators}
        />
        <RestrictedBookRoute
          key="reports"
          path={`${match?.path}/feedback/reports`}
          component={Reports}
        />
        <RestrictedBookRoute
          key="feedback"
          path={`${match?.path}/feedback/comments`}
          component={Comments}
        />
        {/* <RestrictedBookRoute key='feedback' path={`${match?.path}/feedback/reading-data`} component={Comments} /> */}
        <RestrictedBookRoute
          key="feedback"
          path={`${match?.path}/feedback/exits`}
          component={Comments}
        />
        <RestrictedBookRoute
          key="feedback"
          path={`${match?.path}/feedback/reviews`}
          component={Reviews}
        />
        <RestrictedBookRoute
          key="surveys"
          path={`${match?.path}/surveys`}
          component={Survey}
        />
        <RestrictedBookRoute
          key="posts"
          path={`${match?.path}/feed`}
          component={BookPost}
        />
        <RestrictedBookRoute
          key="posts"
          path={`${match?.path}/posts`}
          component={BookPost}
        />
        <RestrictedBookRoute
          key="settings"
          path={`${match?.path}/settings`}
          component={BookOverview}
        />
        <RestrictedBookRoute
          key="cover"
          path={`${match?.path}/cover`}
          component={BookCover}
        />
        <RestrictedBookRoute
          key="messages"
          path={`${match?.path}/messages`}
          component={BookMessages}
        />
        <RestrictedBookRoute
          key="followers"
          path={`${match?.path}/followers`}
          component={BookFollowers}
        />
      </Switch>
    </BookContainer>
  );
};

export default BookPage;
