// useAuthentication.js
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  loggedIn,
  updateIdToken,
  getUserProfile,
  loggedOut,
} from '../modules/user';
import { toggleAppSidebar } from '../modules/app';
import fire from '../config/fire';
import { authUtil } from '../utils';
import {
  ProxyEventTypes,
  useHostAppProxy,
} from 'src/contexts/HostAppProxyContext';

const useAuthentication = () => {
  const {
    settings: { customAuthToken },
    sendMessageToHost,
  } = useHostAppProxy();
  const dispatch = useDispatch();
  const idTokenRefreshTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const ct = customAuthToken;
    const ctTimestamp = Date.now();
    if (ct && ctTimestamp) {
      const timestamp = new Date(ctTimestamp);
      const timeSince = Date.now() - timestamp.getTime();
      if (timeSince > 60 * 60 * 1000) {
        window.localStorage.removeItem('firebaseIdToken');
        window.localStorage.removeItem('firebaseIdTokenTimestamp');
      } else {
        fire
          .auth()
          .signInWithCustomToken(ct)
          .then(userCredential => {
            sendMessageToHost({
              type: ProxyEventTypes.USER_LOGGED_IN,
              data: {
                user: userCredential.user ? userCredential.user.uid : null,
              },
            });
          })
          .catch(error => {
            console.error('Error signing in with custom token:', error);
            sendMessageToHost({
              type: ProxyEventTypes.MESSAGE,
              data: `Error signing in with custom token: ${error}`,
            });
          });
      }
    }
  }, [customAuthToken]);

  useEffect(() => {
    const refreshIdToken = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(updateIdToken(idToken));
    };

    const unsubscribeAuthStateChanged = fire.auth().onAuthStateChanged(
      user => {
        console.log('onAuthStateChanged', user);

        if (user) {
          user
            .getIdToken()
            .then(idToken => {
              window.localStorage.setItem('br-storage-key', user.uid);
              window.localStorage.setItem('br-is-anonymous', user.isAnonymous);
              dispatch(loggedIn(user, idToken));
              dispatch(getUserProfile(idToken));

              if (idTokenRefreshTimer.current) {
                clearInterval(idTokenRefreshTimer.current);
              }
              idTokenRefreshTimer.current = setInterval(
                refreshIdToken,
                60 * 5 * 1000
              );
            })
            .catch(err => console.error(err));
        } else {
          window.localStorage.removeItem('br-storage-key');
          if (idTokenRefreshTimer.current) {
            clearInterval(idTokenRefreshTimer.current);
          }
          dispatch(loggedOut());
          dispatch(toggleAppSidebar(false));
        }
      },
      error => {
        console.error('onAuthStateChanged error:', error);
      }
    );

    const unsubscribeIdTokenChanged = fire.auth().onIdTokenChanged(
      user => {
        console.log('onIdTokenChanged', user);
        if (user) {
          refreshIdToken();
        }
      },
      error => {
        console.error('onIdTokenChanged error:', error);
      }
    );

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuthStateChanged();
      unsubscribeIdTokenChanged();
      if (idTokenRefreshTimer.current) {
        clearInterval(idTokenRefreshTimer.current);
      }
    };
  }, []);

  // signal to host app that the webapp is ready
  useEffect(() => {
    sendMessageToHost({
      type: ProxyEventTypes.WEB_APP_READY,
    });
  }, []);
};

export default useAuthentication;
