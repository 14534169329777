import React, { useEffect } from 'react';
import {
  Divider,
  Dimmer,
  Header,
  Segment,
  Card,
  Button,
} from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import firebase from '../../../config/fire';
import { resetActiveAccount, setActiveAccount } from '../../../modules/account';

import { loggedOut } from '../../../modules/user';
import { useHostAppProxy } from 'src/contexts/HostAppProxyContext';

const LAST_ACTIVE_ACCOUNT_KEY = 'br-laa';

const AccountSelector = () => {
  const { t } = useTranslation();
  const { signOut } = useHostAppProxy();
  const dispatch = useDispatch();
  const userProfile = useSelector((state: any) => state.user.userProfile);
  const activeAccount = useSelector(
    (state: any) => state.account.activeAccount
  );

  useEffect(() => {
    if (userProfile.accountMembership.length === 1 && !activeAccount) {
      // only one membership, go for that account immediately
      _setActiveAccount(userProfile.account);
    } else {
      // multiple memberships, go for pre-selected account or ask user
      const lastActiveAccountId = window.localStorage.getItem(
        LAST_ACTIVE_ACCOUNT_KEY
      );
      const activeMembership = userProfile.accountMembership.find(
        (membership: any) => membership.account._id === lastActiveAccountId
      );
      console.log('Account activeMembership', activeMembership);

      if (activeMembership) {
        _setActiveAccount(activeMembership.account);
      }
    }
  }, []);

  const _setActiveAccount = account => {
    window.localStorage.setItem(LAST_ACTIVE_ACCOUNT_KEY, account._id);
    dispatch(setActiveAccount({ account }));
  };

  const handleSignout = () => {
    dispatch(resetActiveAccount());
    dispatch(loggedOut());
    signOut();
    firebase.auth().signOut();
  };

  if (!userProfile || activeAccount) {
    return null;
  }

  return (
    <Dimmer active>
      <Segment>
        <Header size="large">{t('Account')}</Header>
        <Card.Group itemsPerRow={1}>
          {userProfile.accountMembership.map(membership => (
            <Card
              key={membership.account._id}
              onClick={() => _setActiveAccount(membership.account)}
              style={{ color: 'black' }}
            >
              <Card.Content
                textAlign="left"
                header={[
                  <span key="name">
                    {membership.account.owner.displayName}
                  </span>,
                ]}
                description={t(`member_role_${membership.role}`)}
              />
            </Card>
          ))}
        </Card.Group>
        <Divider hidden />
        <Button
          basic
          onClick={e => {
            e.preventDefault();
            handleSignout();
          }}
          icon="log out"
          content={t('SignOut')}
        />
      </Segment>
    </Dimmer>
  );
};

export default AccountSelector;
